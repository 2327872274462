<template>
  <div
    class="views_active_page_plan_success_page d-flex align-center justify-center"
  >
    <div class="box_group d-flex flex-column align-center justify-center">
      <div class="icon"></div>
      <div class="title">您已成功提交</div>
      <div class="button btn_dark d-flex align-center justify-center"
        @click="handleAddMore"
      >继续添加多个申请</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    add_more_router_name: {
      type: String,
      default: 'active_page_plan'
    }
  },
  mounted(){
    document.documentElement.style.fontSize = '13.333333vw';
    // this.w_height = window.innerHeight;
  },
  beforeDestroy(){
    document.documentElement.style.fontSize = '14px';
  },
  methods:{
    handleAddMore(){
      this.$router.push({name: this.add_more_router_name});
    }
  }
}
</script>

<style lang="scss" scoped>
.views_active_page_plan_success_page{
  width: 100%;
  height: 100vh;
  .icon{
    background: {
      image: url(~@/assets/images/active_page/plan/success.png);
      size: 100% 100%;
    }
    width: 1.6rem;
    height: 1.6rem;
  }
  .title{
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    line-height: 56px;
  }
  .button{
    margin-top: 1.47rem;
    font-size: 16px;
    line-height: 0.9rem;
    padding: 0 0.2rem;
    border-radius: 4px;
    width: 6rem;
    text-align: center;
  }
}
</style>