var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "views_active_page_plan_success_page d-flex align-center justify-center",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "box_group d-flex flex-column align-center justify-center",
        },
        [
          _c("div", { staticClass: "icon" }),
          _c("div", { staticClass: "title" }, [_vm._v("您已成功提交")]),
          _c(
            "div",
            {
              staticClass: "button btn_dark d-flex align-center justify-center",
              on: { click: _vm.handleAddMore },
            },
            [_vm._v("继续添加多个申请")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }